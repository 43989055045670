import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import heroStyles from "./hero.module.scss"

export default () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "hero-book@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 266, maxHeight: 472) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <section className={heroStyles.wrapper}>
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-5">
                        <div className={`${heroStyles.bookImageWrapper}`}>
                            <Img fluid={data.placeholderImage.childImageSharp.fluid} imgStyle={{objectFit: "contain"}} alt="This Way Guide book cover" />
                        </div>
                    </div>
                    <div className="cell large-7 medium-7 small-12">
                        <h1 className={`${heroStyles.headline} show-for-medium`}>Are you ready to wake up every morning excited for your day? Filled with joy?</h1>
                        <h2 className={`${heroStyles.subheader} show-for-medium`}>Then this book is for you! These powerful teachings will give you the guidance to live a more fulfilling life in which you are awake and alive with light and happiness each day.</h2>
                        <h2 className={`${heroStyles.subheader} show-for-small-only`}>These powerful teachings will give you the guidance to live a more fulfilling life in which you are awake and alive with light and happiness each day.</h2>
                        <div className="text-center">
                            <a href="http://www.lulu.com/shop/linda-schafer/this-way-a-spiritual-guide-to-life/paperback/product-22091780.html" className="button large secondary small-only-expanded"><FontAwesomeIcon icon="shopping-cart" />&nbsp;&nbsp;&nbsp;Order Now!</a>
                        </div>
                        <div className="text-center">
                            <a className={heroStyles.previewLink} href="http://www.lulu.com/shop/linda-schafer/this-way-a-spiritual-guide-to-life/paperback/product-22091780.html">Preview the book</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
