import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Book from "../components/book"
import Author from "../components/author"
import Purchase from "../components/purchase"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout>
    <SEO
      title="This Way, A Spiritual Guide to Life"
      description="This Way is a book that will give you the guidance to live a more fulfilling life in which you are awake and alive with light and happieness each day."
      keywords="This Way Guide, guide to life, spiritual guide, spirituality, self help, self-improvement, angels, channeling, soul alignment"
    />
    <main id="main" role="main" className="main">
      <Hero />
      <Book />
      <Author />
      <Purchase />
      <Footer />
    </main>
  </Layout>
)

export default IndexPage
