import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./footer.module.scss"

let copyrightYear = new Date().getFullYear();

const Footer = () => (
  <section className={styles.footer}>
    <div className="grid-container">
        <div className="grid-x grid-padding-x">
            <div className="cell large-6 medium-6 small-12">
                <p className={styles.copyright}>&copy; {copyrightYear} This Way Guide. All Rights Reserved.</p>
            </div>
            <div className="cell large-6 medium-6 small-12">
                <div className={styles.rightWrapper}>
                    <p className={`${styles.designedBy} show-for-medium`}>Designed - <span className="fancy-amp">&amp;</span> - Developed by <a href="http://www.brandedclever.com">Branded Clever</a>.</p>
                    <p id="back-top" className={styles.backTop}><Link to="/#site-container">Back to Top&nbsp;&nbsp;<FontAwesomeIcon icon="chevron-circle-up" /></Link></p>
                </div>
            </div>
        </div>
    </div>
  </section>
)

export default Footer
