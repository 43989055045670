import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import styles from "./purchase.module.scss"

export default () => {
    const data = useStaticQuery(graphql`
        query {
            luluImage: file(relativePath: { eq: "lulu-logo@2x.png" }) {
                childImageSharp {
                    fixed(width: 54, height: 30) {
                    ...GatsbyImageSharpFixed
                    }
                }
            }
            barnesImage: file(relativePath: { eq: "barnes-noble-logo@2x.png" }) {
            childImageSharp {
                fixed(width: 162, height: 25) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        amazonImage: file(relativePath: { eq: "amazon-logo@2x.png" }) {
            childImageSharp {
                fixed(width: 149, height: 30) {
                ...GatsbyImageSharpFixed
                }
            }
        }
        journeysImage: file(relativePath: { eq: "journeys-logo@2x.png" }) {
            childImageSharp {
                fixed(width: 78, height: 35) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
    `)
    return (
        <section className={styles.purchaseWrapper}>
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="cell large-7 medium-7 small-12">
                        <h1 className={`${styles.purchaseTitle} alpha`}>Purchase Now</h1>
                        <p>You can purchase <em><strong>This Way, A Spiritual Guide to Life</strong></em> at a variety of online retailers. We’ll also be working with local retailers in your area to bring the book to a store near you.</p>
                        <div className={styles.purchaseButtonWrapper}>
                            <a href="http://www.lulu.com/shop/linda-schafer/this-way-a-spiritual-guide-to-life/paperback/product-22091780.html" className="button large small-only-expanded"><FontAwesomeIcon icon="shopping-cart" />&nbsp;&nbsp;&nbsp;Order Now</a>
                        </div>
                    </div>
                    <div className="cell large-4 large-offset-1 medium-4 medium-offset-1 small-12">
                        <h3 className={styles.purchaseRetailerHeader}>Also available online from:</h3>
                        <ul className={`${styles.purchaseRetailersList} no-bullet cf`}>
                            <li className={`${styles.purchaseListItem} ${styles.purchaseListItemLulu}`}>
                                <a href="http://www.lulu.com/shop/linda-schafer/this-way-a-spiritual-guide-to-life/paperback/product-22091780.html">
                                    <Img fixed={data.luluImage.childImageSharp.fixed} className={styles.purchaseListItemImage} imgStyle={{objectFit: "contain"}} alt="Lulu logo" />
                                </a>
                            </li>
                            <li className={`${styles.purchaseListItem} ${styles.purchaseListItemBarnes}`}>
                                <Img fixed={data.barnesImage.childImageSharp.fixed} className={styles.purchaseListItemImage} imgStyle={{objectFit: "contain"}} alt="Barnes and Noble logo" />
                            </li>
                            <li className={`${styles.purchaseListItem} ${styles.purchaseListItemAmazon}`}>
                                <Img fixed={data.amazonImage.childImageSharp.fixed} className={styles.purchaseListItemImage} imgStyle={{objectFit: "contain"}} alt="Amazon logo" />
                            </li>
                            <li className={`${styles.purchaseListItem} ${styles.purchaseListItemJourneys}`}>
                                <Img fixed={data.journeysImage.childImageSharp.fixed} className={styles.purchaseListItemImage} imgStyle={{objectFit: "contain"}} alt="Journeys of Life logo" />
                            </li>
                        </ul>
                        <div className="text-center">
                            <div className={styles.purchaseFacebookButton}><a href="http://www.facebook.com/thiswayguide" title="Join us on Facebook"><FontAwesomeIcon icon={['fab', 'facebook-square']} />&nbsp;&nbsp;Join us on Facebook</a></div>
                        </div>
                        <p className={styles.purchaseQuestions}>Should you have questions, contact us at <strong><a href="mailto:info@thiswayguide.com">info@thiswayguide.com</a></strong>.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
