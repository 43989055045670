import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./author.module.scss"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      authorImage: file(relativePath: { eq: "author-photo@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 240, maxHeight: 308) {
                ...GatsbyImageSharpFluid
              }
          }
      }
    }
  `)
  return (
    <section className={styles.author}>
        <div className="grid-container">
            <div className="grid-x grid-padding-x">
                <div className="cell large-4 medium-4 small-12">
                    <div className={styles.authorPhotoWrapper}>
                        <Img fluid={data.authorImage.childImageSharp.fluid} alt="Linds Schafer bio photo" />
                    </div>
                    <span className={`${styles.authorMeta} ${styles.authorMetaName}`}>Linda Schafer</span>
                    <span className={`${styles.authorMeta}`}>Author and Editor</span>
                    <span className={`${styles.authorMeta} ${styles.authorMetaEmail}`}><a href="mailto:info@thiswayguide.com">info@thiswayguide.com</a></span>
                </div>
                <div className="cell large-8 medium-8 small-12">
                    <h2 className={`${styles.authorTitle} h1`}>About the Author</h2>
                    <p>Linda Schafer has long been a student of holistic health, spirituality, meditation and energy work while living on both the east and west coasts and spending much time at Esalen Institute in Big Sur, California.</p>
                    <p>She considers herself to be more a curator of this angelic channeled information and feels privileged to be able to bring this message to support people in creating a higher quality, more fulfilling life based on these powerful teachings.</p>
                    <p><a className={styles.authorLink} href="http://www.thiswayguide.com">www.thiswayguide.com</a></p>
                </div>
            </div>
        </div>
    </section>
  )
}
