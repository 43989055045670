import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import bookStyles from "./book.module.scss"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      bookImage: file(relativePath: { eq: "book-front@2x.jpg" }) {
          childImageSharp {
              fluid(maxWidth: 304, maxHeight: 459) {
                ...GatsbyImageSharpFluid
              }
          }
      }
      goldenImage: file(relativePath: { eq: "golden-buddha@2x.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 90, maxHeight: 82) {
              ...GatsbyImageSharpFluid
            }
        }
      }
    }
  `)
  return (
    <section className={bookStyles.book}>
        <div className="grid-container">
            <div className="grid-x grid-padding-x">
                <div className="cell medium-8">
                    <h2 className={`${bookStyles.bookTitle} h1`}>About the Book</h2>
                    <p>In 2006 Linda's sister Anita started channeling direct information from the Hierarchy of Angels, a group of 23 angels. This book is a transcription of those amazing channeling sessions.</p>
                    <p>Learn the power of living in alignment with your soul. Transform at your own pace with understandable, single topic chapters and free workbook exercises that you can practice alone or with others.</p>
                    <p>In this program we wish to share with you the power of becoming fully and deeply aligned with your soul. A power that can make a profound change in your life. So come with us on the most incredible journey – into your soul.</p>
                    <p>For inspiration and events, join us on <a href="http://www.facebook.com/thiswayguide">Facebook</a>.</p>
                </div>
                <div className="cell medium-4">
                    <div className={`${bookStyles.bookWrapper} text-center`}>
                        <Img fluid={data.bookImage.childImageSharp.fluid} className={bookStyles.bookPhoto} alt="This Way Guide book cover" />
                    </div>
                    <ul className={`${bookStyles.shareList} no-bullet`}>
                        <li className={bookStyles.shareListItem}><a target="_blank" rel="noreferrer noopener" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.thiswayguide.com" title="Share on Twitter"><FontAwesomeIcon icon={['fab', 'twitter']} size="2x" /></a></li>
                        <li className={bookStyles.shareListItem}><a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/sharer.php?u=www.thiswayguide.com" title="Share on Facebook"><FontAwesomeIcon icon={['fab', 'facebook-f']} size="2x" /></a></li>
                        <li className={bookStyles.shareListItem}><a target="_blank" rel="noreferrer noopener" href="https://pinterest.com/pin/create/button/?url=www.thiswayguide.com" title="Share on Pinterest"><FontAwesomeIcon icon={['fab', 'pinterest']} size="2x" /></a></li>
                    </ul>
                    <div className="text-center">
                        <h5 className={bookStyles.specialThanks}>Special thanks to...</h5>
                        <div className={bookStyles.goldenPhotoWrapper}>
                          <Img fluid={data.goldenImage.childImageSharp.fluid} alt="This Way Guide book cover" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
